import React, { useEffect, useState, useCallback } from "react"
import { Link, navigate } from "gatsby"
import Logo from "../images/logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { faStar, faTimesCircle, faTrashAlt } from "@fortawesome/free-regular-svg-icons"
import { useSearchContext, FilterType } from "./SearchContextProvider"
import classnames from "classnames"
import { useLocation } from "@reach/router";

function selectedCountText(count: number): string {

  if (count == 0) {
    return 'Aucune action sélectionnée'
  } else if (count == 1) {
    return '1 action sélectionnée'
  } else {
    return `${count} actions sélectionnées`
  }
}

function selectedCountButtonText(count: number): string {

  if (count == 1) {
    return 'Soumettre mon action'
  } else {
    return `Soumettre mes ${count} actions`
  }
}

const Navbar = () => {

  const { actions: { setFilter, unselectAction }, filters: { actions, all, filtertype, filtervalue }, form } = useSearchContext();

  const location = useLocation()

  const [query, setQuery] = useState(filtertype == FilterType.query && filtervalue != undefined ? filtervalue : "");
  const [showMenu, setShowMenu] = useState(false)

  const closeMenu = () => setShowMenu(false)
  const openMenu = () => {
    if (actions.length > 0) {
      setShowMenu(true)
    }
  }

  const [showSearchMenu, setShowSearchMenu] = useState(false)
  const closeSearchMenu = () => setShowSearchMenu(false)
  const openSearchMenu = () => setShowSearchMenu(true)

  useEffect(() => {
    if (query != "") {
      setFilter(FilterType.query, query)
    }
  }, [query]);

  useEffect(() => {
    if (filtertype != FilterType.query) {
      setQuery("")
    }
  }, [filtertype]);

  let selectedActions = all.filter(a => actions.includes(a.externalID))

  const remove = useCallback(
    (id: string) => {
      unselectAction(id)
    },
    [actions]
  )

  useEffect(() => {
    if (actions.length == 0) {
      closeMenu()
    }
  }, [actions]);

  return (
    <div>
      <nav className="fixed top-0 inset-x-0 z-30 bg-primary text-white font-sans h-20 shadow flex flex-row">
        <Link to="/" className="md:w-1/4 flex flex-row">
          <div className="self-center ">
            <img
              src={Logo}
              alt="Comment agir dans son entreprise pour le climat"
              className="h-20 "
            />
          </div>
          <div className="sm:text-base lg:text-lg leading-none self-center ml-4">Comment agir <br /> dans son entreprise<br /> <span className="font-bold">pour le climat?</span></div>
        </Link>
        <div className="bg-white text-black hidden md:flex flex-grow">
          <div
            className="relative my-auto text-primary mx-4 w-full"
          >
            <form className="flex items-center w-full" onSubmit={event => { if (location.pathname != "/") { navigate(`/`); } }}>
              <input
                value={query}
                className="text-xs border border-gray-300 bg-white placeholder-gray-700 rounded py-2 pr-10 pl-4 block w-full appearance-none leading-normal"
                style={{ minWidth: "260px" }}
                onChange={event => setQuery(event.target.value)}
                placeholder="Lancer une recherche"
              />
              <FontAwesomeIcon
                icon={faSearch}
                className="mr-4 absolute right-0 text-secondary cursor-pointer"
                onClick={() => { 
                  if (location.pathname != "/") { 
                    navigate(`/`); 
                  }

                }}
              />
            </form>
          </div>
          <div className="flex bg-white text-primary text-xs font-bold leading-none self-center text-right  cursor-pointer"
            onClick={openMenu}>
            {selectedCountText(actions.length)}
          </div>
          <FontAwesomeIcon
            icon={faStar}
            className="mx-4 text-secondary cursor-pointer self-center"
            onClick={openMenu}
          />
        </div>
      </nav>

      <div className="fixed mt-20 w-full z-30 flex bg-white text-primary text-xs font-bold leading-none self-center items-center py-4 px-2 mt-20 sm:hidden">
        <div className="w-1/2 text-center cursor-pointer  border-r" onClick={openSearchMenu}>
          Recherche
          <FontAwesomeIcon
            icon={faSearch}
            className="ml-2 text-secondary cursor-pointer"

          />
        </div>
        <div className="w-1/2 text-center cursor-pointer flex flex-row justify-center items-center"
          onClick={openMenu}>
          <div className="w-20 text-left">
            {selectedCountText(actions.length)}
          </div>
          <FontAwesomeIcon
            icon={faStar}
            className="ml-2 text-secondary cursor-pointer "
          />
        </div>
      </div>

      <div
        className={classnames(
          "bg-white font-bold text-primary h-screen fixed z-50 flex flex-col px-4 transition-all duration-700 shadow-xl rounded-l-lg",
          "w-full sm:w-1/3 sm:mr-0 sm:ml-auto",
          showMenu ? "inset-x-0" : "-right-full"

        )}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="cursor-pointer mt-4 absolute top-0 right-2 text-gray-400	text-2xl"
          onClick={closeMenu}
        />
        <h2 className="text-xl mt-6">Ma sélection d'actions<br /> pour le climat dans mon entreprise</h2>
        <div className="flex">

          <a href={form + actions.join(",")} target="_blank"
            className={classnames(
            form ? "block" : "hidden",
            "my-5 mr-2 bg-secondary rounded text-sm font-bold py-3 px-4 text-primary text-center transition duration-300 ease transition-colors hover:bg-primary hover:text-secondary"
          )}
          >{selectedCountButtonText(actions.length)}</a>

          <Link className={classnames(
            "my-5 bg-secondary bg-opacity-40 rounded text-sm font-bold py-3 px-4 text-primary text-center transition duration-300 ease transition-colors hover:bg-primary hover:text-secondary"
          )}
            to={"/share?actions=" + actions.join(",")}
          >Partager</Link>
        </div>
        <div className="flex flex-col overflow-y-auto">
          {
            selectedActions?.map(action => (
              <div key={action.id} className="border-t border-gray-300 py-3">
                <div className="flex flex-row">
                  <img className="self-center mr-1" style={{ width: "30px", height: "30px" }} src={action.theme.pictoURL} />

                  <span className="self-center text-xs font-extralight ">{action.theme.name}</span>
                  <div className="self-center flex-grow text-right">
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="cursor-pointer text-sm text-gray-400"
                      onClick={() => remove(action.externalID)}
                    />
                  </div>
                </div>
                <Link to={action.slug} className="text-sm">{action.name} <span className="text-gray-600 font-light">#{action.externalID}</span></Link>
              </div>
            ))
          }
        </div>
      </div>

      <div className={classnames(showSearchMenu ? "block" : "hidden", "bg-white h-screen fixed z-50 w-full pt-20 px-6 sm:hidden")}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="cursor-pointer mt-4 absolute top-0 right-2 text-gray-400	text-2xl"
          onClick={closeSearchMenu}
        />
        <form className="flex items-center w-full" onSubmit={event => { if (location.pathname != "/") { navigate(`/`); } }}>
          <input
            value={query}
            className="text-xs border border-gray-300 bg-white placeholder-gray-700 rounded py-2 pr-10 pl-4 block w-full appearance-none leading-normal"
            style={{ minWidth: "260px" }}
            onChange={event => setQuery(event.target.value)}
            placeholder="Lancer une recherche"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="mr-4 absolute right-4 text-secondary cursor-pointer"
            onClick={() => { if (location.pathname != "/") { navigate(`/`); }  closeSearchMenu() }}
          />
        </form>
      </div>
    </div>
  )
}

export default Navbar
