import React from "react"
import Navbar from "./Navbar"
import "./layout.css"
import { SearchContextProvider } from "./SearchContextProvider"
import Footer from "./Footer"
import Seo from "./Seo"

const Layout = ({ children }) => {
  
  return (
    <SearchContextProvider>
      <Seo />
      <div className="flex flex-col min-h-screen antialiased bg-beige w-full">
        <Navbar />
        <main className={`flex-grow pt-36 sm:pt-20 font-serif flex flex-row`}>{children}</main>
        <Footer />
      </div>
    </SearchContextProvider>
  )
}


export default Layout